import { useEffect, useState } from 'react';
import constate from 'constate';
import { graphql, useStaticQuery } from 'gatsby';

const useApp = () => {
  const [basket, setBasket] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [basketEmptied, setBasketEmptied] = useState(false);
  const [basketValue, setBasketValue] = useState(0);
  const [basketWeight, setBasketWeight] = useState(0);
  const [orderStatus, setOrderStatus] = useState('not started');

  const products = useStaticQuery(graphql`
    query {
      allGoogleProductsSheet {
        edges {
          node {
            slug
            title
            price
            images
            category
            sku
            weight
          }
        }
      }
    }
  `);

  const spreadsheetId = '1oex5iPuL8ZOThhno7gBRPfHCM0rgeR3v31dWEqrR0qg';
  const apiUrl = 'https://google-sheets.onrender.com/data';
  const sheet = 'products';
  async function fetchData() {
    const response = await fetch(
      `${apiUrl}?id=${spreadsheetId}&sheet=${sheet}`
    );
    const result = await response.json();
    setProductsData(result);
  }

  const productData = products.allGoogleProductsSheet.edges.map(
    (item) => item.node
  );

  const socialMediaLinks = {
    instagram:
      'https://www.instagram.com/marionancientart?igsh=Y3VuNG5vbHM5MWgx',
    tiktok: 'https://www.tiktok.com/@marionancientart?lang=en',
    // Add or remove links as needed
  };

  useEffect(() => {
    if (basket.length === 0) {
      setBasketValue(0);
    } else {
      setBasketValue(basket.reduce((acc, item) => acc + item.price, 0));
      setBasketWeight(basket.reduce((acc, item) => acc + item.weight, 0));
    }
  }, [basket]);

  return {
    basketEmptied,
    basket,
    basketValue,
    basketWeight,
    products: productData,
    orderStatus,
    productsData,
    setOrderStatus,
    setBasketEmptied,
    setBasket,
    socialMediaLinks,
    fetchData,
  };
};

const [AppProvider, useAppContext] = constate(useApp);
export { AppProvider, useAppContext };
