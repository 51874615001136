import React from 'react';

import { NotificationProvider } from './src/context/AddItemNotificationProvider';
import { AppProvider } from './src/context/useAppContext';

export const wrapRootElement = ({ element }) => (
  <NotificationProvider>
    <AppProvider>{element}</AppProvider>
  </NotificationProvider>
);
